import { Router as MyRouter } from "@reach/router"
import React from "react"
import { RandomPerson } from "../modules/RandomPerson"

const Router = () => {
  return (
    <MyRouter basepath="/app">
      <RandomPerson path="/" />
    </MyRouter>
  )
}
export default Router
