import { RouteComponentProps } from "@reach/router"
import React, { useRef } from "react"
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Projects from "../components/projects"
import Experience from "../components/experience"
import About from "../components/about"
import Contact from "../components/contact"
import SEO from "../components/seo"
/** @jsx jsx */
import { useColorMode, jsx } from "theme-ui"
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import SVG from "../components/svg"
import { UpDown, UpDownWide, waveAnimation } from "../styles/animations"
// @ts-ignore
import Intro from "../sections/intro"
// @ts-ignore
import ExperienceMDX from "../sections/experiences"
// @ts-ignore
import ProjectsMDX from "../sections/projects"
// @ts-ignore
import ContactMDX from "../sections/contact"
import styled from "@emotion/styled"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Link } from "gatsby"
import "../styles/Label.css"
import Footer from "../components/footer"

interface Props extends RouteComponentProps {}

export const RandomPerson: React.FC<Props> = () => {
  const offsets = { hero: 0, experience: 1, projects: 4, contact: 5.1 }
  const factors = { hero: 1, experience: 3, projects: 2, contact: 1 }
  const InnerWave = styled.div`
    path {
      ${waveAnimation(`20s`)};
    }
  `
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`)
  }
  return (
    <Layout>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <SEO title="Home" />
      <Parallax pages={6}>
        <div className="Hero">
          <Divider speed={0.2} offset={offsets.hero} factor={factors.hero}>
            <UpDown>
              <SVG
                icon="triangle"
                hiddenMobile
                width={48}
                stroke
                color="icon_orange"
                left="10%"
                top="20%"
              />
              <SVG
                icon="hexa"
                width={48}
                stroke
                color="icon_red"
                left="60%"
                top="70%"
              />
              <SVG
                icon="box"
                width={6}
                color="icon_darker"
                left="60%"
                top="15%"
              />
            </UpDown>
            <UpDownWide>
              <SVG
                icon="arrowUp"
                hiddenMobile
                width={16}
                color="icon_blue"
                left="80%"
                top="10%"
              />
              <SVG
                icon="triangle"
                width={12}
                stroke
                color="icon_brightest"
                left="90%"
                top="50%"
              />
              <SVG
                icon="circle"
                width={16}
                color="icon_darker"
                left="70%"
                top="90%"
              />
              <SVG
                icon="triangle"
                width={16}
                stroke
                color="icon_darkest"
                left="30%"
                top="65%"
              />
              <SVG
                icon="cross"
                width={16}
                stroke
                color="icon_pink"
                left="28%"
                top="15%"
              />
              <SVG
                icon="circle"
                width={6}
                color="icon_darkest"
                left="75%"
                top="10%"
              />
              <SVG
                icon="upDown"
                hiddenMobile
                width={8}
                color="icon_darkest"
                left="45%"
                top="10%"
              />
            </UpDownWide>
            <SVG
              icon="circle"
              hiddenMobile
              width={24}
              color="icon_darker"
              left="5%"
              top="70%"
            />
            <SVG
              icon="circle"
              width={6}
              color="icon_darkest"
              left="4%"
              top="20%"
            />
            <SVG
              icon="circle"
              width={12}
              color="icon_darkest"
              left="50%"
              top="60%"
            />
            <SVG
              icon="upDown"
              width={8}
              color="icon_darkest"
              left="95%"
              top="90%"
            />
            <SVG
              icon="upDown"
              hiddenMobile
              width={24}
              color="icon_darker"
              left="40%"
              top="80%"
            />
            <SVG
              icon="triangle"
              width={8}
              stroke
              color="icon_darker"
              left="25%"
              top="5%"
            />
            <SVG
              icon="circle"
              width={64}
              color="icon_green"
              left="95%"
              top="5%"
            />
            <SVG
              icon="box"
              hiddenMobile
              width={64}
              color="icon_purple"
              left="5%"
              top="90%"
            />
            <SVG
              icon="box"
              width={6}
              color="icon_darkest"
              left="10%"
              top="10%"
            />
            <SVG
              icon="box"
              width={12}
              color="icon_darkest"
              left="40%"
              top="30%"
            />
            <SVG
              icon="hexa"
              width={16}
              stroke
              color="icon_darker"
              left="10%"
              top="50%"
            />
            <SVG
              icon="hexa"
              width={8}
              stroke
              color="icon_darker"
              left="80%"
              top="70%"
            />
          </Divider>
          <Content
            sx={{ variant: `texts.bigger` }}
            speed={0.4}
            offset={offsets.hero}
            factor={factors.hero}
          >
            <Inner>
              <Intro />
              <span id="button-container">
                <a
                  href="https://drive.google.com/file/d/1JdghiDJm_fV5THp8Q8R5Cp_xO1rJGIGt/view?usp=sharing"
                  target="_black"
                  style={{
                    position: "relative",
                    display: "inline-block",
                    padding: "12px 36px",
                    margin: "10px 0",
                    color: "#fff",
                    textDecoration: "none",
                    textTransform: "uppercase",
                    fontSize: 18,
                    letterSpacing: 2,
                    borderRadius: 40,
                    background: "linear-gradient(90deg,#1062c8,#55e7fc",
                    marginRight: 10,
                  }}
                >
                  Resume
                </a>
                <label id="switch" className="switch" sx={{}}>
                  <input
                    type="checkbox"
                    onChange={toggleColorMode}
                    id="slider"
                  />
                  <span className="slider round"></span>
                </label>
                {/* <Link
                to="/#contact"
                style={{
                  position: "relative",
                  display: "inline-block",
                  padding: "12px 36px",
                  margin: "10px 0",
                  color: "#fff",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontSize: 18,
                  letterSpacing: 2,
                  borderRadius: 40,
                  background:
                    "linear-gradient(90deg,#1062c8,rgb(72, 187, 120)",
                  marginRight: 10,
                }}
              >
                Contact
              </Link> */}
              </span>
              <ContactMDX />
            </Inner>
          </Content>
        </div>
        <div className="experience">
          <Divider
            bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
            sx={{ clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)` }}
            speed={-0.2}
            offset={offsets.experience}
            factor={factors.experience}
          />
          <Content
            speed={0.4}
            offset={offsets.experience + 0.2}
            factor={factors.experience}
          >
            <Inner>
              <div
                sx={{
                  display: `grid`,
                  gridGap: [4, 4, 4, 5],
                  gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
                  h2: { gridColumn: `-1/1`, color: `heading !important` },
                }}
              >
                <ExperienceMDX />
              </div>
            </Inner>
          </Content>
          <Divider
            speed={0.1}
            offset={offsets.experience}
            factor={factors.experience}
          >
            <UpDown>
              <SVG
                icon="box"
                width={6}
                color="icon_brightest"
                left="85%"
                top="75%"
              />
              <SVG
                icon="upDown"
                width={8}
                color="icon_teal"
                left="70%"
                top="20%"
              />
              <SVG
                icon="triangle"
                width={8}
                stroke
                color="icon_orange"
                left="25%"
                top="5%"
              />
              <SVG
                icon="circle"
                hiddenMobile
                width={24}
                color="icon_brightest"
                left="17%"
                top="60%"
              />
            </UpDown>
            <UpDownWide>
              <SVG
                icon="arrowUp"
                hiddenMobile
                width={16}
                color="icon_green"
                left="20%"
                top="90%"
              />
              <SVG
                icon="triangle"
                width={12}
                stroke
                color="icon_brightest"
                left="90%"
                top="30%"
              />
              <SVG
                icon="circle"
                width={16}
                color="icon_yellow"
                left="70%"
                top="90%"
              />
              <SVG
                icon="triangle"
                hiddenMobile
                width={16}
                stroke
                color="icon_teal"
                left="18%"
                top="75%"
              />
              <SVG
                icon="circle"
                width={6}
                color="icon_brightest"
                left="75%"
                top="10%"
              />
              <SVG
                icon="upDown"
                hiddenMobile
                width={8}
                color="icon_green"
                left="45%"
                top="10%"
              />
            </UpDownWide>
            <SVG
              icon="circle"
              hiddenMobile
              width={6}
              color="icon_brightest"
              left="4%"
              top="20%"
            />
            <SVG
              icon="circle"
              width={12}
              color="icon_pink"
              left="80%"
              top="60%"
            />
            <SVG
              icon="box"
              width={6}
              color="icon_orange"
              left="10%"
              top="10%"
            />
            <SVG
              icon="box"
              width={12}
              color="icon_yellow"
              left="29%"
              top="26%"
            />
            <SVG
              icon="hexa"
              width={16}
              stroke
              color="icon_red"
              left="75%"
              top="30%"
            />
            <SVG
              icon="hexa"
              width={8}
              stroke
              color="icon_yellow"
              left="80%"
              top="70%"
            />
          </Divider>
        </div>
        <div className="projects">
          <Content
            speed={0.4}
            offset={offsets.projects + 0.2}
            factor={factors.projects}
          >
            <Inner>
              <div
                sx={{
                  display: `grid`,
                  gridGap: [4, 4, 4, 5],
                  gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
                  h2: { gridColumn: `-1/1`, color: `heading !important` },
                }}
              >
                <ProjectsMDX />
              </div>
            </Inner>
          </Content>
          <Divider
            speed={0.1}
            offset={offsets.projects}
            factor={factors.projects}
          >
            <UpDown>
              <SVG
                icon="box"
                width={6}
                color="icon_brightest"
                left="85%"
                top="75%"
              />
              <SVG
                icon="upDown"
                width={8}
                color="icon_teal"
                left="70%"
                top="20%"
              />
              <SVG
                icon="triangle"
                width={8}
                stroke
                color="icon_orange"
                left="25%"
                top="5%"
              />
              <SVG
                icon="circle"
                hiddenMobile
                width={24}
                color="icon_brightest"
                left="17%"
                top="60%"
              />
            </UpDown>
            <UpDownWide>
              <SVG
                icon="arrowUp"
                hiddenMobile
                width={16}
                color="icon_green"
                left="20%"
                top="90%"
              />
              <SVG
                icon="triangle"
                width={12}
                stroke
                color="icon_brightest"
                left="90%"
                top="30%"
              />
              <SVG
                icon="circle"
                width={16}
                color="icon_yellow"
                left="70%"
                top="90%"
              />
              <SVG
                icon="triangle"
                hiddenMobile
                width={16}
                stroke
                color="icon_teal"
                left="18%"
                top="75%"
              />
              <SVG
                icon="circle"
                width={6}
                color="icon_brightest"
                left="75%"
                top="10%"
              />
              <SVG
                icon="upDown"
                hiddenMobile
                width={8}
                color="icon_green"
                left="45%"
                top="10%"
              />
            </UpDownWide>
            <SVG
              icon="circle"
              hiddenMobile
              width={6}
              color="icon_brightest"
              left="4%"
              top="20%"
            />
            <SVG
              icon="circle"
              width={12}
              color="icon_pink"
              left="80%"
              top="60%"
            />
            <SVG
              icon="box"
              width={6}
              color="icon_orange"
              left="10%"
              top="10%"
            />
            <SVG
              icon="box"
              width={12}
              color="icon_yellow"
              left="29%"
              top="26%"
            />
            <SVG
              icon="hexa"
              width={16}
              stroke
              color="icon_red"
              left="75%"
              top="30%"
            />
            <SVG
              icon="hexa"
              width={8}
              stroke
              color="icon_yellow"
              left="80%"
              top="70%"
            />
          </Divider>
        </div>
        <div id="foo\bar">
          <Divider
            fill="divider"
            speed={0.2}
            offset={offsets.contact}
            factor={factors.contact}
          >
            <div
              sx={{
                position: `absolute`,
                bottom: 0,
                width: `full`,
                transform: `matrix(1, 0, 0, -1, 0, 0)`,
              }}
            >
              <InnerWave
                sx={{
                  position: `relative`,
                  height: `full`,
                  svg: { width: `100%`, height: `40vh` },
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="contact-wave"
                  viewBox="0 0 800 338.05"
                  preserveAspectRatio="none"
                >
                  <defs>
                    <linearGradient id="linGrad">
                      <stop offset="0" stopColor="SlateBlue" />
                      <stop offset="100%" stopColor="DeepSkyBlue" />
                    </linearGradient>
                  </defs>
                  <path>
                    <animate
                      attributeName="d"
                      values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                      repeatCount="indefinite"
                      dur="30s"
                    />
                  </path>
                </svg>
              </InnerWave>
            </div>
          </Divider>
          <Content
            speed={0.4}
            offset={offsets.contact - 0.02}
            factor={factors.contact}
          >
            <Footer />
          </Content>
          <Divider
            speed={0.1}
            offset={offsets.contact - 0.1}
            factor={factors.contact}
          >
            <UpDown>
              <SVG
                icon="upDown"
                hiddenMobile
                width={8}
                color="icon_darkest"
                left="70%"
                top="20%"
              />
              <SVG
                icon="triangle"
                width={8}
                stroke
                color="icon_darkest"
                left="25%"
                top="5%"
              />
            </UpDown>
            <UpDownWide>
              <SVG
                icon="triangle"
                width={12}
                stroke
                color="icon_brightest"
                left="95%"
                top="50%"
              />
              <SVG
                icon="circle"
                width={6}
                color="icon_brightest"
                left="85%"
                top="15%"
              />
              <SVG
                icon="upDown"
                hiddenMobile
                width={8}
                color="icon_darkest"
                left="45%"
                top="10%"
              />
            </UpDownWide>
            <SVG
              icon="circle"
              width={6}
              color="icon_brightest"
              left="4%"
              top="20%"
            />
            <SVG
              icon="circle"
              width={12}
              color="icon_darkest"
              left="70%"
              top="60%"
            />
            <SVG
              icon="box"
              width={12}
              color="icon_darkest"
              left="20%"
              top="30%"
            />
            <SVG
              icon="hexa"
              width={8}
              stroke
              color="icon_darkest"
              left="80%"
              top="70%"
            />
            <p id="contact"></p>
          </Divider>
        </div>
      </Parallax>
    </Layout>
  )
}
